import React from 'react';

import {compareItemsPath} from "../../../lib/urlPaths";

/**
 *
 * 2024-11 modernised even more
 * 2023-11 modernised
 *
 * @param id
 * @param handleSelect
 * @param compareList
 * @returns {JSX.Element}
 * @constructor
 */
export default function CompareNow({id, handleSelect, compareList}) {
  const handleClick = e => {
    if (compareList.length < 2) {
      alert("Please select at least two items.");
      return;
    }

    window.location = compareItemsPath(compareList);
  }

  // Do NOT use a label here, as it will want to proxy clicks to the checkbox
  // use an A, because it links to something outside this page
  return <div className="hidden lg:block mt-2">
    <span className="inline-block rounded-2xl bg-brand-primary cursor-pointer pr-2 py-1 hover:shadow-lg">
      <input
        className="align-middle m-1 ml-3"
        id={id} type="checkbox" value="1"
        onChange={handleSelect} checked={true}/>

      <a className="align-middle text-white hover:text-white text-sm font-normal m-1 pb-1 leading-tight"
        onClick={handleClick}>Compare Now</a>
    </span>
  </div>
}

