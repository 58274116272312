/*
** DO NOT EDIT THIS FILE! All changes will be overwritten.
** edit the master in app/javascript/themes/carlyle/_template_config.js,
** then run script/activate-theme carlyle
*/

//
//
//  ####     ##    #####   #        #   #  #       ######
// #    #   #  #   #    #  #         # #   #       #
// #       #    #  #    #  #          #    #       #####
// #       ######  #####   #          #    #       #
// #    #  #    #  #   #   #          #    #       #
//  ####   #    #  #    #  ######     #    ######  ######
//

// This file is installed into config/javascript/siteConfigVars.js
// run script/activate-theme after making any changes

export const SITE_CONFIG = {
  theme: 'carlyle',
}


/*
** config/javascript/siteConfigVars.js
** installed by ./script/activate-theme carlyle
** at Mon Dec 30 11:01:00 PM UTC 2024 on carl01
*/
