import React from 'react';
import RatingWidget from "./RatingWidget";

// 2023-11 modernised
// 2024-11 modernised some more
export default function GridItemTitle({item, path, handleClick= (e => true)}) {

  return (
    <div className="leading-tight">
      <div className=" align-top text-left text-md min-h-14">
        <a className="text-gray-800 group-hover:text-brand-orange group-hover:underline"
          href={path} onClick={handleClick}>{item.name}</a>
      </div>

    </div>
  );

}
