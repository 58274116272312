import React, {useEffect, useState} from 'react';

import {requestBraintreeClient} from "../../logic/braintreeHelper";

// braintreeData prop is just an object that has a "token" member.
export default function BraintreeClientProvider({render, token, braintreeData={}}) {

  const [braintreeClient, setBraintreeClient ] = useState(null);

  token ||= braintreeData?.token;

  useEffect(() => {
    // Request a braintree client. When it arrives, put it in state,
    // so it can be passed to children
    if (token && !braintreeClient) {
      requestBraintreeClient({token})
        .then(client => {
          console.log("received braintree client", client)
          // client has getConfiguration, _configuration, _graphQL, _request
          setBraintreeClient(client);
        })
    }
  }, [braintreeClient, token]);

  if (!braintreeClient)
    console.info("BraintreeClientProvider: WARNING client not yet available; rendering without.")

  return render({ braintreeClient });
}
