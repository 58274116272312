import React, {useState, useEffect} from 'react';
import clsx from 'clsx';

export default function FilterWidgetHeadline({
  gridId,
  headline,
  handleToggleOpen = (e => null),
  isOpen=false,
  ...props}) {

  const iconClass = isOpen ? "fa fa-angle-down" : "fa fa-angle-up";

  return <h4 className="relative border-gray-300 border-b pb-2" >
    <a className="text-md font-normal uppercase text-gray-600 font-sans"
      onClick={handleToggleOpen}>
      {headline}
      <span className={clsx("font-normal absolute right-2 top-1", iconClass)}></span>
    </a>
  </h4>
}
