import React from 'react';
import PropTypes from 'prop-types';

import GridItem from "./GridItem";

import clsx from "clsx";
import { useDispatch } from "react-redux";

/**
 * 2023-04 MH - modernized this component and all components it uses.
 *
 * ProductGroupGrid - usually part of GroupGridApp (the bulk of a TopCategory page)
 * but could be called without it.
 *
 "dispatch",
 "startupState",
 "gridState",
 "customerVehicle",
 "vehicleContext",
 "callbacks",
 "visibleItems"
 */
export default function StandardGrid({
  gridId,
  visibleItems, gridState,
  customerVehicle
}) {
  const {columns = 4, currentPage = 1} = gridState;

  const dispatch = useDispatch();

  let classNames = clsx("product-grid-inner block clear-both grid grid-cols-2 gap-2 lg:gap-4");
  if (columns >= 4) {
    classNames = clsx(classNames, `lg:grid-cols-${columns - 1}`)
  }
  classNames = clsx(classNames, `xl:grid-cols-${columns}`)

  return (
    <div className={classNames}>
      {
        visibleItems.map((item, i) => <GridItem
          key={item.id}
          gridId={gridId}
          dispatch={dispatch}
          item={item} index={i}
          gridState={gridState}
        />)
      }
    </div>
  );
}

StandardGrid.propTypes = {
  gridState: PropTypes.object.isRequired,
};
