//
// EmbeddedConfig - harvests variables from window.back_end_vars
//
// a = new EmbeddedConfig()
//

export default class EmbeddedConfig {
  static _instance = null;

  constructor(data) {
    this.config = data || globalThis.back_end_vars || {error: 'no config found'};
  }

  // Hi I am a weak singleton. Use global instance if you like or just call new()
  static instance() {
    if (!EmbeddedConfig._instance) {
      EmbeddedConfig._instance = new EmbeddedConfig();
    }
    return EmbeddedConfig._instance;
  }

  static getTheme() {
    let i = EmbeddedConfig.instance();

    return i.fetch('name') || i.fetch('theme');
  }

  static getName() {
    let i = EmbeddedConfig.instance();
    return i.fetch('name');
  }

  static getDisplayName() {
    let i = EmbeddedConfig.instance();
    return i.fetch('displayName');
  }

  static isNola() {
    return EmbeddedConfig.getName() === 'napaonline';
  }

  static isAag() {
    return EmbeddedConfig.getName() === 'aagarage';
  }

  isDev() {
    return this.fetch('env', null) === 'development';
  }

  // newsletterName() {
  //   if (EmbeddedConfig.getName() === 'aagarage')
  //     return "AAG Insiders Program";
  //
  //   return "Mailing List"
  // }

  fetch(key, dflt = null) {
    let {config} = this;

    // 2023-06 automatically try snake/camel variants
    for (let k of [key, _.snakeCase(key), _.camelCase(key)]) {
      if (config[k] || config.hasOwnProperty(k))
        return config[k];
    }

    console.log("warning - app config has no property :" + key + ", using default: " + dflt);
    return dflt;
  }

  fetchKey(name, deflt = null) {
    let keys = this.fetch('keys', {});

    let value = keys[name];
    if (!value) {
      console.warn(`EmbeddedConfig.fetchKey: no ${name} in ${JSON.stringify(keys)}`);
    }

    return value || deflt;
  }
}

export function configFetch(key, deflt = null) {
  let i = EmbeddedConfig.instance();
  return i.fetch(key, deflt);
}

export function isNola() { return EmbeddedConfig.isNola() }

export function isAag() { return EmbeddedConfig.isAag() }
