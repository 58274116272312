import React from 'react';
import GridItem from "./GridItem";
import clsx from 'clsx';
import { uniqBy } from 'lodash';

/**
 * Return a linear grid of items.
 *
 * @param dispatch
 * @param gridState
 * @param items
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function LinearProductGrid({
  gridId,
  className,
  gridClasses,
  itemClasses,
  dispatch, gridState, items = [],
  startIndex = 0,  // offset for indexes, especially below-the-fold
  ...props
}) {

  startIndex = +startIndex;

  if (!items?.map) {
    console.error("GridItemsList: expected items=(Array), got: ", items);
    return null;
  }

  items = uniqBy(items, i => i.id);

  // test insufficient item count -
  //items = _.slice(items, 0, 3);

  className = clsx('overflow-x-scroll', className);
  gridClasses = clsx(`linear-product-grid grid gap-2 pb-4`, gridClasses);

  //  removed:
  //   mobileColumns = +(mobileColumns || columns);
  //  grid-cols-${mobileColumns} md:grid-cols-${columns}
  // pics are 240

  return <div className={className}>
    <div className={gridClasses}>
      {items.map((item, i) => <GridItem key={item.id}
        className={itemClasses}
        gridId={gridId}
        dispatch={dispatch}
        item={item}
        index={i + startIndex}
      />)}
    </div>
  </div>

}
