import React from 'react';
import _ from 'lodash';

import FilterCheckboxLabel from "./FilterCheckboxLabel";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {filterNamesBefore} from "../logic/filterLogic";
import ResetAllFilters from "./ResetAllFilters";
import {useDispatch} from "react-redux";
import FilterWidgetHeadline from "./FilterWidgetHeadline";
import clsx from 'clsx';
import FilterWidgetContent from "../sidebar/FilterWidgetContent";

// 2023-11 MH modernised
export default function FilterWidget({
  gridId,
  filter,
  disabledOptions = [],
  // options, buckets, selections are the hashes for THIS FILTER ONLY
  options = {},
  selections = {},
  filtersTouched,
  className="mb-4"
}) {
  const dispatch = useDispatch();

  const filterName = filter.name;

  // all choices available, as an array
  const optionList = _(options)
    .values().sortBy("sortOrder").value()

  const {displayMode = 'open'} = filter;
  const isOpen = (displayMode === 'open');
  const disabledMap = _.keyBy(disabledOptions);
  // let disabledCount = _.size(disabledMap);

  // toggle this filter widget open/closed
  const handleToggleOpen = evt => {
    dispatch(gridFilterSlice.actions.toggleOpenClosed({
      gridId,
      filterName
    }));
  }


  return (
    <div className={clsx(className)} id={`${filterName}-filter`}>
      <FilterWidgetHeadline
        gridId={gridId}
        filterName={filterName}
        headline={filter.displayName || _.capitalize(filterName)}
        handleToggleOpen={handleToggleOpen}
        isOpen={isOpen}
      />

      <FilterWidgetContent
        isOpen={isOpen}>
        {optionList.map(option => {
          const disabled = disabledMap[option.id];

          if (disabled) return null;

          return <FilterCheckboxLabel
            gridId={gridId}
            key={option.id}
            filter={filter}
            filterOption={option}
            disabled={disabled}
            selected={!!(selections["" + option.id])}
            filtersTouched={filtersTouched}
          />
        })}
      </FilterWidgetContent>

    </div>
  )
}

