import React from 'react';

import GridSizeControl from "./GridSizeControl";
import GridSortControl from "./GridSortControl";
import GridFilterActivator from "./GridFilterActivator";
import clsx from "clsx";


/**
 * 2024-05 multi-grid aware
 * 2023-11 modernised, excess props removed.
 *
 * TOP: SIZE and SORT widgets; SIZE is hidden for Mobile
 * BOTTOM: PAGINATOR, SIZE and SORT widgets; SORT is hidden for Mobile
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GridTopControlBar({
  gridId,
  placement, // "top" or "bottom"
  gridState,
  filterState,
  gridItems = [],
  filteredItems = [],
  visibleItems = []
}) {
  const {sidebarOpen = false} = filterState;

  // TOP: (MOB: FilterActivator) (DESK: sizeControl), (BOTH: sortControl)
  // BOT: (BOTH: paginator), (DESK: sizeControl), (DESK: sortControl)
  return (
    <div className="relative text-right clearfix md:float-right mb-2">
      <GridFilterActivator
        gridId={gridId}
        className={clsx(
          "grid-filter-activator relative inline-block md:hidden float-left",
          "text-md px-4 py-2 rounded-3xl border border-gray-400 bg-white ")}
        linkClasses="text-gray-700 font-normal"
        sidebarOpen={sidebarOpen}
        caption="Filter"/>

      <GridSizeControl
        gridId={gridId}
        gridItemCount={gridItems?.length}
        filteredItemCount={filteredItems?.length}
        visibleItemCount={visibleItems?.length}
        gridState={gridState}
        className="hidden md:inline-block mx-2"
      />

      <GridSortControl
        gridId={gridId}
        gridState={gridState}
        gridItems={gridItems}
        className="inline-block ml-2"
      />
    </div>
  );
}
