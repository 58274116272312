import React from 'react';
import Money from '../../../lib/Money';

// 2023-11 modernised
export default function GridItemDesc({item}) {
  let price = new Money(item.price);

  return (
    <ul className="list-none text-sm leading-tight">
      <li className="">From {price.toString()}</li>
      <li className="text-brand-primary">Free Shipping
      </li>
    </ul>
  );
}
