import React from 'react';
import {getClasses} from "../../styles";

//
// Shown only on subcategory pages when thisPage prop is
// present, this links back to the parent.
export default function ReturnFromSubcat({
  thisPage, ...props
}) {
  const {title, parentPath, parentName} = thisPage;

  const handleClick = (e) => {
    if (parentPath) {
      e.preventDefault();
      globalThis.location = parentPath;
    }
  }

  // id: we override the usual props.name here
  return (
    <div className="mb-4" id="categories-filter">
      <h4 className={getClasses('filterSidebarH4')}>TYPE</h4>

      <div className="block py-2 mb-2">
        <a className="block clearfix text-gray-900 hover:text-brand-orange"
          href={parentPath}>
          <span className="float-left text-3xl fal fa-arrow-circle-left"/>

          <div className="text-xs leading-none pb-2 ml-10">Back to View All</div>
          <div className="font-bold text-lg leading-none ml-10">{parentName}</div>
        </a>
      </div>

      <div>
        <label className={getClasses('filterCheckboxLabel')}>
          <input type="checkbox" value={1} checked={true} onChange={handleClick}/>
          {' '}
          <a className="pointer-cursor text-gray-800" onClick={handleClick}>{title}</a>
        </label>
      </div>
    </div>
  )
}

// SubCategoryNav.propTypes = {}
