import React from 'react';
import clsx from 'clsx';

// Show #1, #2, etc rankings in a big orange circle - used on shop by vehicle pages
export default function GridItemRank({index = 0}) {
  const classNames = clsx(
    'absolute top-1 right-1 align-middle text-xl h-8 w-8 rounded-full',
    'whitespace-nowrap text-center bg-brand-orange text-white'
  )
  return (
    <div className={classNames}>
      <sup className="relative text-sm -mr-1">{'# '}</sup>
      <strong>{(+index) + 1}</strong>
    </div>
  );
}
