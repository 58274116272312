import React from 'react';
import LinearProductGrid from "../grid/LinearProductGrid";

/**
 * 2024 - simplified - no longer uses Redux or gridState
 *
 * Formerly GroupUpSellsApp, now renamed to
 * LinearProductGridApp
 *
 * @param gridId
 * @param items
 * @param props
 * @param showControls
 * @returns {JSX.Element}
 * @constructor
 */
export default function LinearProductGridApp({
  gridId,
  items,
  // NOTUSED showControls = false, // prev/next buttons
  //columns = null, // pass thru
  //mobileColumns = null,
  ...props
}) {

  // const scroll = (direction) => {
  //   // console.log("I knew I should have taken that " + direction + " turn at Albuquerque.")
  // }
  //
  // const scrollLeft = e => scroll('left');
  // const scrollRight = e => scroll('right');

  return (
      <LinearProductGrid
        gridId={gridId}
        items={items}
        {...props}
      />
  );

  // the group-hover div at the bottom replaces scrollbar when not hovered
}

//       <div className="group-hover:hidden h-4"/>
// { showControls && <div className="tools-left"><a className="scroll-button" onClick={scrollLeft}><i
//   className="fas fa-arrow-alt-circle-left"/></a></div> }

