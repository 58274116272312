import React from 'react';
import PropTypes from 'prop-types';

import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {setGridPage} from "../../../ReduxStore/GroupGrid/groupGridSlice";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";

// 2024-05 gridId aware
// 2023-11 MH modernised
export default function FilterCheckboxLabel({
  gridId,
  filter,
  filterOption,
  disabled = false,   // NO LONGER SUPPORTED, we exclude the disabled ones
  selected = false,
  filtersTouched = [],
  className = "",
  linkClasses = "text-sm font-normal text-gray-700 hover:text-brand-primary"
}) {

  const dispatch = useDispatch();

  // link to target only if filters are untouched
  const targetPath = _.isEmpty(filtersTouched) ?
    targetPathFor(filterOption) : null;

  const handleClick = e => {
    if (targetPath) {
      document.location = targetPath;
      return;
    }

    // now tell gridFilterSlice that we've changed a filter selection
    const payload = {
      gridId,
      filterName: filter.name,
      id: filterOption.id,
      selected: !selected
    };

    dispatch(gridFilterSlice.actions.selectFilterItem(payload))

    // go back to page 1 of product grid, as this change might reduce the number of pages possible
    setGridPage({dispatch, gridId, page: 1});
  }

  return (<label className={clsx(className)}>
    <input type="checkbox"
      name={`${gridId}-${filterOption.id}`}
      value={filterOption.id}
      className="checkbox-simple"
      disabled={disabled}
      onChange={disabled ? null : handleClick} checked={selected}/>

    {targetPath ?
      <a className={clsx(linkClasses)} href={targetPath}>{filterOption.name}</a> :
      <span className={clsx(linkClasses)}>{filterOption.name}</span>
    }
  </label>);
}

function targetPathFor(filterOption) {
  if (filterOption.path)
    return LocalUrlMaker.prefixedPath(filterOption.path);
  return null;
}

FilterCheckboxLabel.propTypes = {
  gridId: PropTypes.string.isRequired,
  flavour: PropTypes.string,
  filterName: PropTypes.string,   // one of: categories, brands
  selected: PropTypes.bool,
  filterOption: PropTypes.shape({
    id: PropTypes.string,     // 133020
    name: PropTypes.string,  // Neoprene Seat Covers
    sortOrder: PropTypes.number,  // 0
    count: PropTypes.number, // 5
    path: PropTypes.string  // /Seat-Covers/Neoprene
  }).isRequired
}
