export default function CheckToCompare({id, selected, handleSelect}) {
  // HIDDEN on mobile.

  return (
    <div className="hidden lg:block mt-3">
      <label className="font-normal text-sm pointer-cursor">
        <input id={id} type="checkbox" value="1"
          onChange={handleSelect} checked={selected}/>
        <span>Check to Compare</span>
      </label>
    </div>
  );
}
