import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {cartToKlaviyoProps, withKlaviyo} from "../../../ExternalServices/Klaviyo/KlaviyoHelpers";

const isDev = (process.env.NODE_ENV == 'development');

export default function KlaviyoViewCartReporter({shoppingCart, ...props}) {
  const [reported, setReported] = useState(null);

  useEffect(() => {
    if (reported) return;

    const klaviyoCart = cartToKlaviyoProps(shoppingCart);
    setReported(klaviyoCart);

    // afterKlaviyoIdentifySent( k => {...} )
    withKlaviyo(k => {
      k.track('Viewed Cart', klaviyoCart)
    });
  }, [shoppingCart, reported]);

  // if (isDev) {
  //   return <div className="debug">
  //     <h2 className="text-xl">KlaviyoViewCartReporter</h2>
  //     <pre>{JSON.stringify(reported, null, 2)}</pre>
  //   </div>
  // }
  return null;
}
