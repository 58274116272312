import React, {useState, useEffect} from 'react';
import clsx from 'clsx';

export default function FilterWidgetContent({
  className, isOpen = false, children, ...props
}) {
  return <div className={
    clsx("grid transition-grid-rows duration-500 ease-in-out",
      isOpen ? "grid-rows-1fr" : "grid-rows-0fr",
      className
    )}>
    <div className={"overflow-y-hidden"}>
      {children}
    </div>
  </div>
}
