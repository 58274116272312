import clsx from 'clsx';

const CLASS_LISTS_DEFAULT = {
  // select widgets within grid sort & size controls
  gridControlSelect: clsx(
    "relative inline-block w-auto appearance-none m-0 px-4 py-2 lg:py-1 mx-1",
    "rounded-3xl text-gray-700 border-1 border-gray-400 bg-white select-double-arrow"
  ),

  filterSidebarH4: clsx(
    'relative font-bold text-gray-700 uppercase text-md border-b border-gray-800 p-2'
  ),

  filterCheckboxLabel: clsx(
    'text-sm font-normal text-gray-800'
  )
}

const CLASS_LISTS = {
  aagarage: {},
  napaonline: {},
  carlyle: {},
  snowplows: {},
  default: CLASS_LISTS_DEFAULT
}

export function getClasses(name, theme = 'default') {
  let sub = CLASS_LISTS[theme] || {};
  let defaults = CLASS_LISTS['default'];

  return clsx(sub[name] || defaults[name]);
}
