import React, {useContext, useRef} from 'react';
import GridTopControlBar from "../controls/GridTopControlBar";
import FilterPillBar from "../controls/FilterPillBar";
import VehicleChangeWatcher from "../../../ProductGroupPage/components/GroupItems/VehicleChangeWatcher";
import GridItemsReporter from "../analytics/GridItemsReporter";
import {camelizeKeys} from "../../../ReduxStore/Helpers/transformObject";
import {editUrlParams} from "../../../Utility/urls";
import GridDisplay from "./GridDisplay";
import CatchErrors from "../../../Utility/CatchErrors";
import GridChangeWatcher from "../controls/GridChangeWatcher";
import {useSkeletonLoading} from "../../../hooks/useSkeletonLoading";
import GridBottomControlBar from "../controls/GridBottomControlBar";

const isDev = (process.env.NODE_ENV == 'development');

// 2022-05: a temporary (ha!) kluge until we can make the
// grid fully dynamic - if user clears the vehicle, reload
// the entire page.
const onVehicleChanged = (changes = {}) => {
  changes = camelizeKeys(changes);

  const {vehicleMakeId, vehicleModelId} = changes;

  if (vehicleMakeId) {
    // going from something to nothing
    if (vehicleMakeId[0] && !vehicleMakeId[1]) {
      // 2024-04 reload the page, clearing the vehicle.
      let url = editUrlParams(null, {"set-vehicle": null});
      globalThis.location = url.toString();
    }
  }
}

/*

 "gridItems",
 "filterMap",
 "allFilterSelections",
 "allFilterOptions",
 "allFilterBuckets",
 "filtersTouched",
 "filterStatus",
 "customerVehicle",
 "vehicleContext"
 */

export default function GridWithControls({
  gridId,
  gridState = {},
  filterState,

  gridItems,
  filteredItems,
  visibleItems,

  customerVehicle, // CAUTION: underscored keys; VehicleChangeWatcher depends on them
  ...props // currentAgent, vehicleContext,
}) {
  // showControls - true by default, unless gridState.noControls is truthy,
  // or the grid has only a single item.
  const showControls = (gridItems.length > 1) && !(gridState?.noControls);

  const flavour = props.flavour || gridState?.flavour;

  const myRef = useSkeletonLoading();

  return (
    <div id={gridId} ref={myRef} className="group-grid-inner">
      <CatchErrors>
        <GridChangeWatcher
          gridId={gridId}
          filterState={filterState}
          gridState={gridState}
        />
      </CatchErrors>

      <CatchErrors>
        {showControls && <GridTopControlBar
          gridId={gridId}
          gridState={gridState}
          filterState={filterState}
          gridItems={gridItems}
          filteredItems={filteredItems}
          visibleItems={visibleItems}
        />}

        <FilterPillBar
          gridId={gridId}
          flavour={flavour}
          gridState={gridState}
          filterState={filterState}
        />
      </CatchErrors>

      <CatchErrors>
        <GridDisplay
          gridId={gridId}
          flavour={flavour}
          gridState={gridState}
          visibleItems={visibleItems}
          customerVehicle={customerVehicle}
        />
      </CatchErrors>

      {showControls && <div className="control-bar-bottom">
        <GridBottomControlBar
          gridId={gridId}
          gridState={gridState}
          gridItems={gridItems}
          filteredItems={filteredItems}
          visibleItems={visibleItems}
        />
      </div>}

      <CatchErrors>

        <VehicleChangeWatcher
          gridId={gridId}
          onVehicleChanged={onVehicleChanged}
          customerVehicle={customerVehicle}/>
      </CatchErrors>

      <CatchErrors>
        <GridItemsReporter
          gridId={gridId}
          gridState={gridState}
          flavour={flavour}
          gridItems={gridItems}/>
      </CatchErrors>

    </div>
  )
    ;

}
