import React from 'react';

import {reportGridEvent} from "../../../Analytics/googleGridAdapter";
import groupGridSlice from "../../../ReduxStore/GroupGrid/groupGridSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {getClasses} from "../../styles";

/**
 * 2024-05 multi-grid aware, and scrolls to grid.
 * 2023-11 modernised, consolidated single-use components into this file
 *
 * @param className
 * @param gridItemCount
 * @param filteredItemCount
 * @param visibleItemCount
 * @param gridState
 * @returns {Element}
 * @constructor
 */
export default function ActiveGridSizeControl({
  gridId,
  className = null,
  gridItemCount = 0, // total items loaded on page
  filteredItemCount = 0, // how many survived the filters
  visibleItemCount = 0, // how many on the current page
  gridState = {},
}) {
  const dispatch = useDispatch();

  const {pageSize} = gridState;

  const handleChange = (e) => {
    const newSize = e?.target?.value;

    dispatch(groupGridSlice.actions.updateGridState({
      gridId: gridId,
      gridState: { pageSize: newSize }
    }));

    // Not jumping to page 1 or scrolling to top.
    // If they're at the bottom, embiggening the page
    // brings in more items right where their eyes already are.
    reportGridEvent({
      eventType: 'product_grid_size',
      params: {page_size: newSize}
    });

    // scrollToGrid(gridId);
  }

  return (
    <div className={clsx(className)}>
      Showing {' '}

      <GridCountSelector onChange={handleChange}
        value={pageSize}
        defaultPageSize={gridState?.defaultPageSize}
        limit={gridItemCount || filteredItemCount}/>

      {' of '}
      <strong>{filteredItemCount}</strong>
      {' products'}
    </div>
  );
}

function GridCountSelector({onChange, value = "all", limit, defaultPageSize,}) {

  // force numeric
  const nLimit = isNaN(limit) ? 9999 : +limit;

  // defaultPageSize =
  // grid_state = grid_state || this.props.gridState || {};
  const options = _([defaultPageSize, 24, 48, 96])
    .sortBy(x => x || 0)
    .uniq()
    .filter(n => n && n <= nLimit)
    .value();

  const classNames = getClasses('gridControlSelect');

  return <select className={classNames} name="pageSize" value={value} onChange={onChange}>
    {options.map((i) =>
      <option key={i} value={i}>{i}</option>)}
    <option value="all">all</option>
  </select>
}

