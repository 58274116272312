import React, {useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from "clsx";
import {getClasses} from "../../styles";
import FilterWidgetHeadline from "../filters/FilterWidgetHeadline";
import FilterWidgetContent from "./FilterWidgetContent";

const isDev = (process.env.NODE_ENV == 'development'); //FIXME REMOVE

/**
 * 2023-11 Modernised
 *
 * @param relatedPages
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function RelatedPagesWidget({relatedPages}) {
  const [isOpen, setIsOpen] = useState(false);

  if (_.isEmpty(relatedPages))
    return null;

  const {headline = 'Related Pages', pages} = relatedPages;

  if (_.isEmpty(pages))
    return null;

  const handleToggleOpen = evt => {
    setIsOpen(!isOpen);
  }

  const classNames = ["grid-sidebar-box",
    `grid-sidebar-box-${isOpen ? "open" : "closed"}`,
    "linked-pages"
  ];

  return (
    <div className={clsx(classNames)}>
      <FilterWidgetHeadline
        isOpen={isOpen}
        handleToggleOpen={handleToggleOpen}
        headline={headline}
      />

      <FilterWidgetContent
        isOpen={isOpen}>
        {pages.map((p) => <RelatedPageLink key={p.id} page={p}/>)}
      </FilterWidgetContent>

    </div>
  );
}

RelatedPagesWidget.propTypes = {
  relatedPages: PropTypes.shape({
    flavour: PropTypes.string,
    headline: PropTypes.string,
    pages: PropTypes.array
  })
};

function RelatedPageLink({page}) {
  // // FIXME REMOVE WARNING AFTER FIXING
  if (isDev && page.full_path) {
    console.error("NOT CAMELIZED - ", page);
    return <div className="debug">FIXME CAMELIZE</div>;
  }
  // // FIXME REMOVE WARNING AFTER FIXING

  const {linkText, vehicleName, brandName, fullPath} = page;

  return <div>
    <a href={fullPath}>{linkText || brandName || vehicleName}</a>
  </div>
}
