import React from 'react';
import _ from 'lodash';
import Top10Grid from "../grid/Top10Grid";
import StandardGrid from "../grid/StandardGrid";
import NothingToSeeHere from "../grid/NothingToSeeHere";
import CatchErrors from "../../../Utility/CatchErrors";

export default function GridDisplay({
  gridId,
  flavour,
  gridState = {},
  visibleItems,
  customerVehicle
}) {

  const {currentPage = 1, pageSize = 12, top10} = gridState;

  if (_.isEmpty(visibleItems)) {
    return <NothingToSeeHere
      gridId={gridId}
      customerVehicle={customerVehicle}
    />
  }

  const wantTopTen = (top10 &&
    (currentPage === 1) &&
    (visibleItems.length === 10) &&
    !customerVehicle?.id)

  const InnerGrid = wantTopTen ?
    Top10Grid : StandardGrid;

  return <CatchErrors>
    <InnerGrid
      gridId={gridId}
      flavour={flavour}
      gridState={gridState}
      visibleItems={visibleItems}
      customerVehicle={customerVehicle}
    />
  </CatchErrors>

}
