import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import GridFilterActivator from "../controls/GridFilterActivator";
import RelatedPagesWidget from "./RelatedPagesWidget";
import {isEmpty} from "lodash";
import StaticLinkBox from "./StaticLinkBox";
import FilterWidgetList from "../filters/FilterWidgetList";
import {useSkeletonLoading} from "../../../hooks/useSkeletonLoading";
import CatchErrors from "../../../Utility/CatchErrors";
import clsx from 'clsx';

export default function GroupGridSidebar({
  gridId,
  filterState={},
  relatedPages,
  thisPage,
  sidebarOpen = false,
  linkBoxes = [] // not used?
}) {
  const myRef = useSkeletonLoading();

  const {filterMap} = filterState;

  // FIXME: where does this come from
  const staticLinkBoxes = (linkBoxes || [])
    .filter(b => b && b.links && b.links[0]);

  const classNames = [
    "side-slide-menu",
    "bg-white rounded",
    (sidebarOpen ? 'slide-in' : 'slide-out')
  ];

  // GridFilterActivator provides a close button as a "X" icon, on the
  // page background outside the sliding widget. This handles changes to Open/Close status.
  return (
    <div ref={myRef} className={clsx(classNames)}>

      <CatchErrors>
        <GridFilterActivator
          className="fixed top-0 -right-12 "
          linkClasses="text-white text-4xl p-4"
          gridId={gridId}
          sidebarOpen={sidebarOpen}
          icon="fa fa-times"/>
      </CatchErrors>

      {!isEmpty(filterMap) && <CatchErrors>
        <div className={clsx(
          // appear in a pop-up window at left side of screen
          "max-sm:fixed max-sm:overflow-y-scroll max-sm:overflow-x-hidden max-sm:inset-2"
        )}>
          <FilterWidgetList
            gridId={gridId}
            filterState={filterState}
            thisPage={thisPage}
          />

          {!isEmpty(relatedPages) &&
            <RelatedPagesWidget relatedPages={relatedPages}/>}

          {staticLinkBoxes.map((box, i) =>
            <StaticLinkBox key={i} {...box} />)}
        </div>
      </CatchErrors>}

      <CatchErrors>
        <GridFilterActivator
          gridId={gridId}
          sidebarOpen={sidebarOpen}
          className="fixed bottom-2 left-2 right-2"
          linkClasses="block md:hidden text-center bg-brand-primary rounded text-white font-bold text-xl w-full p-2"
          caption="DONE"
          icon=""/>
      </CatchErrors>
    </div>
  );

}

GroupGridSidebar.propTypes = {
  relatedPages: PropTypes.object,
  linkBoxes: PropTypes.array
}
