import React, {useContext} from 'react';

import GridSizeControl from "./GridSizeControl";
import GridSortControl from "./GridSortControl";
import GridFilterActivator from "./GridFilterActivator";
import GridPaginator from "./GridPaginator";

/**
 * 2024-05 multi-grid aware
 * 2023-11 modernised, excess props removed.
 *
 * TOP: SIZE and SORT widgets; SIZE is hidden for Mobile
 * BOTTOM: PAGINATOR, SIZE and SORT widgets; SORT is hidden for Mobile
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GridBottomControlBar({
  gridId,
  gridState,
  gridItems = [],
  filteredItems = [],
  visibleItems = []
}) {
  // bottom controls: (BOTH: paginator), (DESK: sizeControl), (DESK: sortControl)
  return (
    <div className="relative text-right clearfix mb-4 mt-1">
      <GridPaginator
        gridId={gridId}
        filteredItemCount={filteredItems?.length}
        gridState={gridState}
        className="inline-block mx-2"
      />

      <GridSizeControl
        gridId={gridId}
        gridItemCount={gridItems?.length}
        filteredItemCount={filteredItems?.length}
        visibleItemCount={visibleItems?.length}
        gridState={gridState}
        className="hidden lg:inline-block"
      />

      <GridSortControl
        gridId={gridId}
        gridState={gridState}
        gridItems={gridItems}
        className="hidden lg:inline-block mx-2"
      />
    </div>
  );
}
