import React from 'react';
import {times} from 'lodash';
import {useDispatch} from "react-redux";
import {setGridPage} from "../../../ReduxStore/GroupGrid/groupGridSlice";
import clsx from "clsx";
import {scrollToGrid} from "../logic/gridLogic";
import {reportGridEvent} from "../../../Analytics/googleGridAdapter";

/**
 * 2024-05 multi-grid aware
 * 2024-11 fully tailwind
 *
 * @param gridId
 * @param gridState
 * @param className
 * @param filteredItemCount
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export default function GridPaginator({
  gridId,
  gridState = {},
  className,
  filteredItemCount
}) {

  const {pageSize} = gridState;
  const currentPage = gridState.currentPage ? +gridState.currentPage : 1;

  if (isNaN(pageSize) || (pageSize < 1)) return null;

  const pageCount = Math.ceil(filteredItemCount / pageSize);
  if (pageCount < 2)
    return null;

  // make you an array of [1,2,3..n]
  const pageList = times(pageCount, n => 1 + n)

  return <span className={clsx(className, 'block md:inline-block text-center')}>
    Page:
    {pageList.map(n =>
      <PaginatorLink
        gridId={gridId}
        key={n}
        page={n}
        currentPage={currentPage}
        pageCount={pageCount}/>
    )}
  </span>
}

function PaginatorLink({gridId, page, currentPage, pageCount}) {
  const dispatch = useDispatch();

  const handleClick = e => {
    // also handles URL update
    setGridPage({gridId, page, dispatch});

    scrollToGrid(gridId);

    reportGridEvent({
      eventType: 'product_grid_page',
      params: {page: page}
    });
  }

  const common = "inline-block m-1 px-2 py-1 text-center rounded-md border-2";

  if (page === currentPage) {
    let classNames=clsx(common, "border-brand-blue text-white bg-brand-blue");
    return <strong className={classNames}>{page}</strong>
  } else {
    let classNames=clsx(common, "border-gray-300 text-gray-500 click-me");
    return <a className={classNames} onClick={handleClick}>{page}</a>
  }
}
