import React from 'react';

export default function GridItemPicture({item, path, handleClick}) {

  return (
    <div className="pb-2">
      <a href={path || item.path} onClick={handleClick || (e => true) }>
        <img className="w-full" width="240" height="165" src={item.img} alt={item.name}/>
      </a>
    </div>
  );
}
